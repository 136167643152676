import React from 'react';
import PropTypes from 'prop-types';

import './bullet-point.scss';

const BulletPoint = ({ children }) => {
  return (
    <div className="st-c-bullet-point">
      <div className="st-c-bullet-point__symbol">&rarr;</div>
      <div className="st-c-bullet-point__content">{children}</div>
    </div>
  );
};

BulletPoint.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BulletPoint;
