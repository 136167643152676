import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { useTranslation } from 'react-i18next';

const TravelersHowItWorks = () => {
  const { t } = useTranslation('TravelersPage');
  const data = useStaticQuery(graphql`
    fragment imageFluid on File {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    query {
      step1: file(relativePath: { eq: "illustrations/shop.png" }) {
        ...imageFluid
      }
      step2: file(relativePath: { eq: "illustrations/customs.png" }) {
        ...imageFluid
      }
      step3: file(relativePath: { eq: "illustrations/validation-proof.png" }) {
        ...imageFluid
      }
      step4: file(relativePath: { eq: "illustrations/fly.png" }) {
        ...imageFluid
      }
    }
  `);

  return (
    <div className="columns">
      <div className="column">
        <Img
          fluid={data.step1.childImageSharp.fluid}
          className="st-p-travelers__step-illustration"
        />
        <h3 className="title is-5 is-spaced">{t('howItWorks.step1.title')}</h3>
        <p className="subtitle is-6">{t('howItWorks.step1.paragraph')}</p>
      </div>
      <div className="column">
        <Img
          fluid={data.step2.childImageSharp.fluid}
          className="st-p-travelers__step-illustration"
        />
        <h3 className="title is-5 is-spaced">{t('howItWorks.step2.title')}</h3>
        <p className="subtitle is-6">{t('howItWorks.step2.paragraph')}</p>
      </div>
      <div className="column">
        <Img
          fluid={data.step3.childImageSharp.fluid}
          className="st-p-travelers__step-illustration"
        />
        <h3 className="title is-5 is-spaced">{t('howItWorks.step3.title')}</h3>
        <p className="subtitle is-6">{t('howItWorks.step3.paragraph')}</p>
      </div>
      <div className="column">
        <Img
          fluid={data.step4.childImageSharp.fluid}
          className="st-p-travelers__step-illustration"
        />
        <h3 className="title is-5 is-spaced">{t('howItWorks.step4.title')}</h3>
        <p className="subtitle is-6">{t('howItWorks.step4.paragraph')}</p>
      </div>
    </div>
  );
};

export default TravelersHowItWorks;
