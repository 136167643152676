/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';

import './hero.scss';

const Hero = ({ children }) => (
  <div className="st-hero">
    <div className="container">{children}</div>
  </div>
);

Hero.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Hero;
