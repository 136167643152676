import React from 'react';
import Icon from './icon';
import { useTranslation } from 'react-i18next';

import './travelers-pricing.scss';

const TravelersSubscription = () => {
  const { t } = useTranslation('TravelersPricing');

  return (
    <section>
      <h1 className="is-sr-only">Pricing subscription details</h1>
      <div className="st-travelers-pricing">
        <div className="st-travelers-pricing__labels">
          <div className="st-travelers-pricing__label is-range">
            {t('label.amountSpentWithStamp')}
          </div>
          <div className="st-travelers-pricing__label is-fee">{t('label.subscriptionFee')}</div>
        </div>

        <div className="st-travelers-pricing__table">
          <div className="st-travelers-pricing__line">
            <div className="st-travelers-pricing__range">
              <div className="st-travelers-pricing__range-end">
                <div className="st-travelers-pricing__range-value">{t('label.unlimited')}</div>
              </div>
            </div>
            <div className="st-travelers-pricing__fee">
              <div className="st-travelers-pricing__fee-amount">€29,99</div>
              <div className="st-travelers-pricing__fee-label">
                {t('label.subscriptionRecurrence')}
              </div>
            </div>
          </div>
        </div>

        <div className="st-travelers-pricing__subtitle">{t('label.vatIncluded')}</div>
      </div>
    </section>
  );
};

export default TravelersSubscription;
