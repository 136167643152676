export const formatEuros = value =>
  value
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, '$&.')
    .replace(/\.(\d+)$/g, ',$1');

export const formatEurosWithoutDecimals = value => formatEuros(value).replace(/,(\d+)$/g, '');

export const formatMarks = function(value) {
  if (value >= 1000) {
    return `${value / 1000}K`;
  }
  return value;
};
