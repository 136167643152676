import React from 'react';
import { Trans } from 'react-i18next';

import './savings-calculator.scss';
import 'rc-slider/assets/index.css';
import Slider from 'rc-slider';

import BrandLogo from './brand-logo';
import { formatEuros, formatEurosWithoutDecimals, formatMarks } from '../utils/common';

//const real = [0, 300, 450, 700, 1000];
//const scaled = [0, 300, 500, 2000, 20000];


const real = [0, 70, 150, 300, 400, 600, 700, 800, 900, 1000];
const scaled = [0, 70, 150, 300, 500, 2000, 5000, 10000, 20000, 25000];


const marks = {
  [real[1]]: formatMarks(scaled[1]),
  [real[2]]: formatMarks(scaled[2]),
  [real[3]]: formatMarks(scaled[3]),
  [real[4]]: formatMarks(scaled[4]),
  [real[5]]: formatMarks(scaled[5]),
  [real[6]]: formatMarks(scaled[6]),
  [real[7]]: formatMarks(scaled[7]),
  [real[8]]: formatMarks(scaled[8]),
  [real[9]]: formatMarks(scaled[9]),
};

const getRangeInfo = value => {
  if (value <= real[1]) {
    return {
      real: { min: real[0], max: real[1] },
      scaled: { min: scaled[0], max: scaled[1] },
      stampFee: 0,
    };
  } else if (value <= real[2]) {
    return {
      real: { min: real[1], max: real[2] },
      scaled: { min: scaled[1], max: scaled[2] },
      stampFee: 1.49,
    };
  } else if (value <= real[3]) {
    return {
      real: { min: real[2], max: real[3] },
      scaled: { min: scaled[2], max: scaled[3] },
      stampFee: 3.99,
    };
  } else if (value <= real[4]) {
    return {
      real: { min: real[3], max: real[4] },
      scaled: { min: scaled[3], max: scaled[4] },
      stampFee: 9.99,
    };
  } else if (value <= real[5]) {
    return {
      real: { min: real[4], max: real[5] },
      scaled: { min: scaled[4], max: scaled[5] },
      stampFee: 19.99,
    };
  } else if (value <= real[6]) {
    return {
      real: { min: real[5], max: real[6] },
      scaled: { min: scaled[5], max: scaled[6] },
      stampFee: 24.99,
    };
  } else if (value <= real[7]) {
    return {
      real: { min: real[6], max: real[7] },
      scaled: { min: scaled[6], max: scaled[7] },
      stampFee: 39.99,
    };
  } else if (value <= real[8]) {
    return {
      real: { min: real[7], max: real[8] },
      scaled: { min: scaled[7], max: scaled[8] },
      stampFee: 49.99,
    };
  } else if (value <= real[9]) {
    return {
      real: { min: real[8], max: real[9] },
      scaled: { min: scaled[8], max: scaled[9] },
      stampFee: 1000000,
    };
  }
};

const calculateScaledValue = (value, { real, scaled }) =>
  scaled.min + ((value - real.min) * (scaled.max - scaled.min)) / (real.max - real.min);

const calculateAgencyFees = value => {
  return value * 0.07;
};

const calculateValues = currentValue => {
  const { real, scaled, stampFee } = getRangeInfo(currentValue);
  const scaledValue = calculateScaledValue(currentValue, { real, scaled });
  return {
    realValue: currentValue,
    scaledValue,
    taxRefundAgencyFee: calculateAgencyFees(scaledValue),
    stampFee,
  };
};

class SavingsCalculator extends React.Component {
  constructor() {
    super();

    this.defaultValue = 200;
    this.state = calculateValues(this.defaultValue);
  }

  onSliderChange = currentValue => {
    this.setState(calculateValues(currentValue));
  };

  render() {
    return (
      <div className="box st-c-savings-calculator">
        <h3 className="st-c-savings-calculator__title">
          <Trans i18nKey="SavingsCalculator:title"></Trans>
        </h3>
        <p className="st-c-savings-calculator__subtitle">
          <Trans i18nKey="SavingsCalculator:paragraph"></Trans>
        </p>
        <div className="st-c-savings-calculator__control">
          <div className="st-c-savings-calculator__current-value">
            <span className="st-c-savings-calculator__currency">€</span>
            {formatEurosWithoutDecimals(this.state.scaledValue)}
            {this.state.realValue === real.slice(-1)[0] ? '+' : ''}
          </div>
          <Slider
            className="st-c-savings-calculator__slider"
            value={this.state.realValue}
            onChange={this.onSliderChange}
            marks={marks}
            step={2}
            min={0}
            max={1000}
          ></Slider>
        </div>
        <div className="st-c-savings-calculator__results">
          <div className="st-c-savings-calculator__result">
            <div className="st-c-savings-calculator__label">
              <Trans i18nKey="SavingsCalculator:taxRefundFees"></Trans>
            </div>
            <div className="st-c-savings-calculator__price">
              <span className="st-c-savings-calculator__currency">€</span>
              {formatEurosWithoutDecimals(this.state.taxRefundAgencyFee)}
              {this.state.realValue === real.slice(-1)[0] ? '+' : ''}
            </div>
          </div>
          <div className="st-c-savings-calculator__result is-stamp">
            <div className="st-c-savings-calculator__label">
              <Trans i18nKey="SavingsCalculator:with"></Trans>
              <BrandLogo className="st-c-savings-calculator__logo"></BrandLogo>
            </div>
            {this.state.stampFee === 0 ? (
              <div className="st-c-savings-calculator__price">Free</div>
            ) : this.state.stampFee === 1000000 ? (
              <div className="st-c-savings-calculator__price">1%</div>
            ) : (
              <div className="st-c-savings-calculator__price">
                <span className="st-c-savings-calculator__currency">€</span>
                {formatEuros(this.state.stampFee)}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default SavingsCalculator;
